import React, { useEffect, useState } from "react";
import LoginPage from "./login";

import { Navigate } from "react-router-dom";
import App from "./Home";
import { documentRef, getDocument } from "./services/firebase";
import Loading from "./loading";

function PrivateRoute({ children, ...props }) {
  const [dbUser, setDbUser] = useState(null);
  const username = localStorage.getItem("username");
  const password = localStorage.getItem("password");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const data = await getDocument(documentRef("settings", "login"));
      console.log("0");
      console.log(data);
      console.log(username, password);

      
      if (data.username !== username || data.password !== password) {
        setDbUser(null);
        localStorage.removeItem("username");
        localStorage.removeItem("password");
        console.log("1");
      } else {
        setDbUser(data);
        console.log("2");
      }
      setLoading(false);
    }
    fetchData();
  }, []);
 
  return loading ? (
    <Loading />
  ) : dbUser ? (
    children
  ) : (
    <Navigate to={"/login"} replace={true} />
  );
}

const routes = [
  {
    path: "/login",
    element: <LoginPage />,
  },
  {
    path: "/",
    children: [
      {
        path: "/",
        index: true,
        auth: true,
        element: <App />,
      },
    ],
  },
];

const authCheck = (routes) =>
  routes.map((route) => {
    if (route?.auth) {
      route.element = (
        <PrivateRoute route={route}>{route.element}</PrivateRoute>
      );
    }
    if (route?.children) {
      route.children = authCheck(route.children);
    }
    return route;
  });

export default authCheck(routes);
