import { Phone, WhatsApp } from "@mui/icons-material";
import SearchIcon from "@mui/icons-material/Search";
import {
  Avatar,
  Divider,
  IconButton,
  InputBase,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  Slide,
  useMediaQuery,
  useScrollTrigger,
} from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { green, red } from "@mui/material/colors";
import { alpha, styled, useTheme } from "@mui/material/styles";
import * as React from "react";
import { useEffect, useState } from "react";
import uniqolor from "uniqolor";
import logo from "./asset/logo.png";
import { collectionRef, listenCollection } from "./services/firebase";
import { groupBy } from "./utils";

function Home() {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const [users, setUsers] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [filteredUsers, setFilteredUsers] = useState([]);
  const trigger = useScrollTrigger();
  useEffect(() => {
    listenCollection(collectionRef("users"), (data) => {
      setUsers(data);
      console.log(data);
    });
  }, []);
  useEffect(() => {
    if (searchText === "") {
      setFilteredUsers(groupBy(users, "department"));
    } else {
      const filtered = users.filter((item) => {
        return item.name.toLowerCase().includes(searchText.toLowerCase());
      });

      setFilteredUsers(groupBy(filtered, "department"));
    }
  }, [searchText, users]);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid2 container spacing={2}>
        <Slide appear={false} direction="down" in={!trigger}>
          <AppBar position="sticky" sx={{ background: red[800] }}>
            {isSmall && (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <img
                  src={logo}
                  alt="logo"
                  style={{
                    width: "50%",
                  }}
                />
              </div>
            )}
            <Toolbar sx={{ display: "flex", justifyContent: "center" }}>
              <Typography
                variant="h6"
                noWrap
                component="div"
                sx={{ flexGrow: 1, display: { xs: "none", sm: "block" } }}
              >
                Akgün Elektrik - Rehber
              </Typography>
              <SearchInputStyle onChange={(e) => setSearchText(e.target.value)}>
                <SearchIconWrapper>
                  <SearchIcon />
                </SearchIconWrapper>
                <StyledInputBase
                  placeholder={
                    users ? `${users.length} kişide ara` : "Kişilerde Ara... "
                  }
                  inputProps={{ "aria-label": "search" }}
                />
              </SearchInputStyle>
            </Toolbar>
          </AppBar>
        </Slide>
        <Grid2 xs={0} sm={0} md={3}></Grid2>
        <Grid2
          sx={{ bgcolor: "#fff", overflow: "auto" }}
          xs={12}
          sm={12}
          md={6}
          spacing={isSmall ? 0 : 1}
        >
          <List
            dense={true}
            sx={{
              width: "100%",
              bgcolor: "background.paper",
              overflow: "auto",
              position: "relative",
              "& ul": { padding: 0 },
            }}
            subheader={<li />}
          >
            {filteredUsers.length !== 0 &&
              Object.keys(filteredUsers).map((department) => (
                <li key={`section-${department}`} style={{ width: "auto" }}>
                  <ul>
                    <ListSubheader>{`${department}`}</ListSubheader>
                    <Divider sx={{ bgcolor: "#fff" }} />
                    {filteredUsers[department].length !== 0 &&
                      filteredUsers[department]
                        .sort((a, b) =>
                          (a.name ?? "").localeCompare(b.name ?? "")
                        )
                        .map((user, i) => {
                          const bgColor = uniqolor(department.toString()).color;
                          return (
                            <ListItem
                              key={i}
                              dense={true}
                              sx={{ width: "auto" }}
                            >
                              <ListItemAvatar>
                                <Avatar
                                  variant="rounded"
                                  sx={{
                                    backgroundColor: bgColor.toString(),
                                  }}
                                >
                                  <Typography>{`${
                                    (user.name.split(" ")[0] ?? "")[0] ?? ""
                                  }${
                                    (user.name.split(" ")[1] ?? "")[0] ?? ""
                                  }`}</Typography>
                                </Avatar>
                              </ListItemAvatar>
                              <ListItemText
                                primary={user.name}
                                secondary={`${
                                  user.personNumber
                                    ? "Şahsi hattı"
                                    : "Şirket hattı"
                                } - ${user.phone}`}
                              />

                              <ListItemSecondaryAction>
                                <IconButton
                                  edge="end"
                                  aria-label="delete"
                                  sx={{ marginRight: 1 }}
                                  href={`https://wa.me/+90${user.phone}`}
                                >
                                  <WhatsApp sx={{ color: green[600] }} />
                                </IconButton>
                                <IconButton
                                  edge="end"
                                  aria-label="delete"
                                  href={`tel:+9${user.phone}`}
                                >
                                  <Phone sx={{ color: green[600] }} />
                                </IconButton>
                              </ListItemSecondaryAction>
                            </ListItem>
                          );
                        })}
                  </ul>
                </li>
              ))}
          </List>
        </Grid2>
        <Grid2 xs={0} sm={0} md={3}></Grid2>
      </Grid2>
    </Box>
  );
}

const SearchInputStyle = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  width: "100%",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    [theme.breakpoints.up("sm")]: {
      width: "20ch",
      "&:focus": {
        width: "25ch",
      },
    },
  },
}));

export default Home;
