import React from "react";
import ReactDOM from "react-dom/client";
import LoginPage from "./login";
import { BrowserRouter, useRoutes } from "react-router-dom";
import routes from "./router";

const Main = () => {
  const showRoutes = useRoutes(routes);
  return <>{showRoutes}</>;
};

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter>
    <Main />
  </BrowserRouter>
);
