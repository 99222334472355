import {
    addDoc,
    collection,
    deleteDoc,
    doc,
    getDoc,
    getDocs,
    onSnapshot,
    orderBy,
    query,
    serverTimestamp,
    setDoc,
    updateDoc,
    where,
} from 'firebase/firestore';

import { db } from '../firebase';

export const documentRef = (collectionName, docId) => {
    return doc(db, `${collectionName}`, docId);
};

export const collectionRef = (
    collectionName,
    options = {
        whereField: undefined,
        operator: undefined,
        value: undefined,
        orderByPath: undefined,
        directionStr: undefined,
    }
) => {
    let ref = collection(db, `${collectionName}`);

    const {
        whereField,
        operator,
        value,
        orderByPath,
        directionStr
    } = options;

    if (whereField && operator && value && orderByPath) {
        ref = query(
            ref,
            where(whereField, operator, value),
            orderBy(orderByPath, directionStr)
        );
    } else if (whereField && operator && value) {
        ref = query(
            ref,
            where(whereField, operator, value)
        );
    } else if (orderByPath) {
        ref = query(ref, orderBy(orderByPath, directionStr));
    }
    return ref;
};

export const addDocument = async (collectionName, data) => {
    try {
        data['createdDate'] = serverTimestamp();
        const docRef = await addDoc(collection(db, `${collectionName}`), data);
        await updateDocument(docRef, { id: docRef.id });
        return docRef.id;
    } catch (error) {
        throw new Error(`An error occurred while adding the document: ${error}`);
    }
};

export const deleteDocument = async (
    docRef
) => {
    try {
        await deleteDoc(docRef);
    } catch (error) {
        throw new Error(`An error occurred while deleting the document: ${error}`);
    }
};

export const getDocument = async (ref) => {
    try {
        const res = await getDoc(ref);
        return res.data();
    } catch (error) {
        throw new Error(`An error occurred while retrieving the document: ${error}`);
    }
};

export const listenCollection = async (ref, snapshots) => {
    try {
        onSnapshot(ref, (snap) => {
            const data = snap.docs.map((v) => v.data());
            snapshots(data);
        });
    } catch (error) {
        throw new Error(`An error occurred while listening to the collection: ${error}`);
    }
};

export const getDocuments = async (
    ref
) => {
    try {
        const querySnapshot = await getDocs(ref);
        return querySnapshot.docs.map((doc) => doc.data());
    } catch (error) {
        throw new Error(`An error occurred while retrieving the documents: ${error}`);
    }
};

export const setDocument = async (ref, data) => {
    try {
        data['createdDate'] = serverTimestamp();
        data['updatedDate'] = serverTimestamp();
        await setDoc(ref, data);
    } catch (error) {
        throw new Error(`An error occurred while setting the document: ${error}`);
    }
};

export const updateDocument = async (ref, data) => {
    try {
        data['updatedDate'] = serverTimestamp();
        await updateDoc(ref, data);
    } catch (error) {
        throw new Error(`An error occurred while updating the document: ${error}`);
    }
};

export const updateDocuments = async (
    ref,
    data
) => {
    try {
        const docs = await getDocuments(ref);

        data['updatedDate'] = serverTimestamp();
        docs.forEach(async (v) => {
            const docRef = doc(db, ref.path, v.id);
            await updateDoc(docRef, data);
        });
    } catch (error) {
        throw new Error(`An error occurred while updating the documents: ${error}`);
    }
};

export const deleteDocuments = async (
    ref
) => {
    try {
        const docs = await getDocuments(ref);

        docs.forEach(async (v) => {
            const docRef = doc(db, ref.path, v.id);
            await deleteDoc(docRef);
        });
    } catch (error) {
        throw new Error(`An error occurred while deleting the documents: ${error}`);
    }
};
