import { Box, Typography } from '@mui/material'
import React from 'react'
import logo from "./asset/logo2.png";

function Loading() {
  return (
<Box
      sx={{
        alignItems: "center",
        width: "100%",
        justifyContent: "center",
        display: "flex",
        height: "90vh",
        overflow: "hidden",
        flexDirection: "column",
      }}
    >
      <img src={logo} alt="logo" width={240} />
      <Typography variant="h6">Yükleniyor...</Typography>
    </Box>
  )
}

export default Loading