// ** React Imports
import { useState, useEffect } from "react";

// ** MUI Components
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { TextField } from "@mui/material";
import logo from "./asset/logo2.png";
import { red } from "@mui/material/colors";
import { documentRef, getDocument } from "./services/firebase";
import { Navigate, useNavigate } from "react-router-dom";

const LoginPage = () => {
  const [credentails, setCredentails] = useState({});
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    getDocument(documentRef("settings", "login")).then((data) => {
      setCredentails(data);
      setLoading(false);
    });
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();
    const { username: dbUsername, password: dbPassword } = credentails;
    if (!dbUsername || !dbPassword) {
      alert("Sunucuda bir hata oluştu");
      return;
    }
    if (username === dbUsername && password === dbPassword) {
      localStorage.setItem("username", username);
      localStorage.setItem("password", password);
      navigate("/");
    } else {
      alert("Kullanıcı adı veya şifre hatalı");
    }
  };

  if (
    localStorage.getItem("username") === credentails.username &&
    localStorage.getItem("password") === credentails.password
  ) {
    return <Navigate to={"/"} replace={true} />;
  }
  return loading ? (
    <Box
      sx={{
        alignItems: "center",
        width: "100%",
        justifyContent: "center",
        display: "flex",
        height: "90vh",
        overflow: "hidden",
        flexDirection: "column",
      }}
    >
      <img src={logo} alt="logo" width={240} />
      <Typography variant="h6">Yükleniyor...</Typography>
    </Box>
  ) : (
    <Box
      sx={{
        alignItems: "center",
        display: "flex",
        height: "90vh",
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          p: [6, 12],
          display: "flex",
          alignItems: "center",
          width: "100%",
          justifyContent: "center",
          flexDirection: "column",
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "100%",
            maxWidth: 400,
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            overflow: "hidden",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              overflow: "hidden",
            }}
          >
            <img src={logo} alt="logo" width={240} />
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              overflow: "hidden",
              justifyContent: "center",
            }}
          >
            <Box sx={{ mx: 2 }}>
              <Typography variant="h5">{}</Typography>
              <Typography sx={{ color: "text.secondary" }}>
                {"Rehber uygulaması"}
              </Typography>
            </Box>
          </Box>
          <Box sx={{ display: "flex", flex: "1" }} />
          <Box>
            <Box sx={{ my: 6 }}>
              <Typography sx={{ color: "text.secondary" }}>{""}</Typography>
            </Box>

            <form noValidate autoComplete="off" onSubmit={onSubmit}>
              <Box sx={{ mb: 4 }}>
                <TextField
                  id="username"
                  fullWidth
                  autoFocus
                  label="Kullanıcı adı"
                  onChange={(e) => setUsername(e.target.value)}
                  placeholder=""
                />
              </Box>
              <Box sx={{ mb: 1.5 }}>
                <TextField
                  id="password"
                  fullWidth
                  label="Şifre"
                  type="password"
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Box>
              <Box
                sx={{
                  mb: 1.75,
                  display: "flex",
                  flexWrap: "wrap",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              ></Box>
              <Button
                fullWidth
                type="submit"
                // onClick={onSubmit}
                variant="contained"
                sx={{ mb: 4, p: 1.5, bgcolor: red[800] }}
              >
                Giriş
              </Button>
            </form>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default LoginPage;
